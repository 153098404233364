import "./intro.scss"
import React from "react"

const Index = () => (
  <section className={"intro"}>
    <div className={"container"}>
      <div className={"intro__textContainer"}>
        <span className={"intro__brand"}>Coqoon</span>
        making ideas <br className="phone-only" />
        come alive
      </div>
    </div>
  </section>
)

export default Index

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Highlight from "../components/highlight"

const IndexPage = () => (
  <Layout>
    <SEO
      title="COQOON"
      keywords={[
        `coqoon`,
        `web services`,
        `rapid prototyping`,
        `pwa`,
        `web services`,
      ]}
    />
    {/*<div className={'container'}>*/}
    <Intro />
    {/* <section>
      <div className={"container"}>
        <h2>We make great ideas tangible</h2>
        <h3>Content first</h3>
        <h3>See your ideas becoming alive</h3>
        <h3>Awesome UX - tested</h3>
      </div>
    </section>
    <section>
      <div className={"container"}>
        <h2>Brands that trust in us</h2>
      </div>
    </section>
    <section>
      <div className={"container"}>
        <h2>Projects</h2>
      </div>
    </section> */}
    {/* <Highlight color={"black"}>Making great ideas tangible</Highlight> */}
    {/*</div>*/}
  </Layout>
)

export default IndexPage
